<template>
<div class="mainform">
    <div class="form">
        <el-form ref="form" class="content" :model="form" label-width="100px" :disabled="false">
          <div class="col col8">
            <el-form-item label="地址名称" prop="addressName" :rules="{ required: true, message: '请输入地址名称', trigger: 'blur' }">
                <el-input v-model="form.addressName"></el-input>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="地址属性" prop="addressProperty" :rules="{ required: true, message: '请选择地址属性', trigger: 'blur' }">
                <el-select filterable v-model="form.addressProperty" placeholder="请选择">
                  <el-option label="返修地址" value=1></el-option>
                  <el-option label="仓库地址" value=2></el-option>
                  <el-option label="其他地址" value=3></el-option>
                </el-select>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="地址" prop="detailedAddress" :rules="{ required: true, message: '请输入地址', trigger: 'blur' }">
                <area-selector
                  :required="false"
                  :countrydData = "form.country"
                  :provincedData = "form.province"
                  :citydData = "form.city"
                  :districtdData = "form.area"
                  @changAction="changAction"></area-selector>
                <el-input v-model="form.detailedAddress"></el-input>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="联系人">
                <el-input v-model="form.contactPerson"></el-input>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="联系方式">
                <el-input v-model="form.contactWay"></el-input>
            </el-form-item>
          </div>
        </el-form>
    </div>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  name: 'AddAddress',
  components: Component.components,
  props: ['formData'],
  data: function () {
    return {
      form: this.formData
    }
  },
  methods: {
    changAction: function (data) {
      if (data.country) {
        this.form.country = data.country
      }
      if (data.province) {
        this.form.province = data.province
      }
      if (data.city) {
        this.form.city = data.city
      }
      if (data.district) {
        this.form.area = data.district
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .mainform{
    background: none;
    padding: 0;
    .form{
      background: none;
      .content{
        padding: 0px 0;
      }
    }
  }
.mainform .form .el-form .el-form-item:last-child {
    margin-bottom: 0px;
}
.areaSelect{
  margin-bottom: 5px;
    :deep(.el-select){
        width: 24% !important;
    }
}
</style>
